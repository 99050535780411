import { useNavigate } from 'react-router-dom';
import './ItemInfo.scss';
import { collections } from '../../../assets/homepage';
import { MintActionType, useMintDispatch } from '../../../context/mint/MintContext';

import IconGem from '@assets/_gemz/icons8-diamond-90.png';
import IconShare from '@assets/_gemz/icons8-share-100.png';
import { useAppState } from '@context/AppContext';

export type ItemInfoProps = {
  enabled: boolean;
  bottomInfoEnabled: boolean;
  type: 'live' | 'register';
  onClick: () => void;
  // children?: React.ReactNode;
  gemz?: {
    username: string;
    gemname: string;
  };
};

export function ItemInfo({ enabled, bottomInfoEnabled, type, onClick, gemz }: ItemInfoProps) {
  const collectionDescription = gemz.gemname;

  const navigate = useNavigate();
  const mintDispatch = useMintDispatch();
  const collectionMedia = {
    ...collections.placeholders,
    // ...(collections[id] || {}),
    ...(gemz && {
      author: gemz.username,
      url: `/${gemz.gemname.replace(/ /g, '_').toLocaleLowerCase().trim()}`,
    }),
    // ...(collectionData || {}),
  };

  function clickButton(e: React.MouseEvent, id: string) {
    // Open bottom drawer
    e.stopPropagation();

    mintDispatch({ type: MintActionType.Reset });

    // homepage will display a bottom drawer modal with <MintPage saleId="id"> as content
    onClick && onClick();
  }

  // console.warn('>>> sale', sale, collectionMedia);

  // todo carles/jb: this data needs to come from the sale. we need the handle string and the url to author's twitter.
  const str = collectionMedia.author.toString().replace(/\s/g, '');
  const authorTwitterHandle = `@${str}`;
  const authorTwitterUrl = 'https://twitter.com'; // todo carles/jb: does not seem to be any author twitter url in sale data.
  // const authorTwitterUrl =
  //   sale.followSocials.find((item) => {
  //     const str2 = item.userId.toString().replace(/\s/g, '');
  //     return str2 === str; // collectionMedia.
  //   }) || '';

  const description = collectionDescription
    .replace(/(\r\n|\n|\r)/gm, '')
    .split('</p>')[0]
    .trim();

  let bottomClassName = '';
  if (useAppState().showGame) {
    bottomClassName = bottomInfoEnabled ? 'enabled' : 'disabled';
  }

  return (
    enabled && (
      <div className={`item-info`}>
        {/* <div className="line-mid-screen" /> */}

        <div className={`item-info-bottom ${bottomClassName}`}>
          <a href={authorTwitterUrl} className="author">
            {authorTwitterHandle}
          </a>
          <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>

        <div className="sidebar">
          <div
            className="sidebar-item author"
            onClick={() => {
              console.warn('>>> tap on author');
            }}
          >
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${collectionMedia.avatar})` }} />
            <div className="sidebar-item-label">{'198\nETH'}</div>
          </div>

          <div
            className="sidebar-item gem"
            onClick={() => {
              console.warn('>>> tap on gem');
            }}
          >
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${IconGem})` }} />
            <div className="sidebar-item-label">{'0.04\nETH'}</div>
          </div>

          <div
            className="sidebar-item share"
            onClick={() => {
              console.warn('>>> tap on share');
            }}
          >
            <div className="sidebar-item-icon" style={{ backgroundImage: `url(${IconShare})` }} />
            <div className="sidebar-item-label">Share</div>
          </div>
        </div>
      </div>
    )
  );
}

export default ItemInfo;
