import { mainSuite } from '@services/ServiceFactory';
import './Notifications.scss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AppActionType, useAppDispatch } from '@context/AppContext';
import eth from './eth.png';
import pimgs from './pimgs.png';
import gpump from './gold_pump.png';
import { TransitioningSection } from '@components/transitioning-section';
import TopBar from '../mint-home/components/TopBar';
import { collections } from '@assets/homepage';

interface Feed {
  id: string;
  created_at: string;
  creator_address: string;
  story_id: string;
  owner_address: string;
  tx_hash: string;
  confirmed: boolean;
  username: string;
  gem_name: string;
  gem_image: string;
  content_url: string;
  profile_image_url?: string;
}

const POLL_INTERVAL = 5000;

let pollInterval: ReturnType<typeof setInterval> | undefined;

const fetchFeed = async () => {
  const response = await fetch('https://pipeline.beta.pnk.one/gems/feed/activity');
  return response.json();
};

const getEllapsedTime = (datePast: string) => {
  var dateFuture = new Date();
  var dateNow = new Date(datePast);
  // @ts-ignore
  var seconds = Math.floor((dateFuture - dateNow) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const getPrettyEllapsedTime = (datePast: string): string => {
  const { days, hours, minutes, seconds } = getEllapsedTime(datePast);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  if (minutes > 0) {
    return `${minutes}m`;
  }
  if (seconds > 0) {
    return `${seconds}s`;
  }
  return 'Error';
};

export const Notifications = () => {
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const [feed, setFeed] = useState<Feed[]>([]);

  const updateFeed = async () => {
    const nextFeed = await fetchFeed();

    if (nextFeed.error) {
      return;
    }

    if (JSON.stringify(nextFeed) !== JSON.stringify(feed)) {
      setFeed(nextFeed);
    }
  };

  // Start polling feed activity
  useEffect(() => {
    clearInterval(pollInterval);

    pollInterval = setInterval(updateFeed, POLL_INTERVAL);

    updateFeed();
  }, [feed]);

  console.log({ feed });

  const notifications = useMemo(
    () =>
      feed.map((entry) => ({
        ...entry,
        name: entry.username,
        time: getPrettyEllapsedTime(entry.created_at),
      })),
    [feed],
  );

  console.warn('>>> notifications', notifications);
  let loaded = notifications.length > 0;

  const onClick = (gemName: string) => {
    // todo carles: we need a better way to figure out urls from gem names.
    // todo carles: prolly gems should have a unique id that matches the url (?)
    const gemRoute = gemName.toLowerCase().split(' ').join('_');
    navigate(`/${gemRoute}`);
  };

  return (
    <div className="notifications">
      <TopBar />
      {/* <div className="header">
        <div className="eth">
          <img src={eth} />
          0.015 ETH
        </div>
      </div> */}
      <div className="nnav">
        <div className="nav-item">You</div>
        <div className="nav-item selected">Your keys</div>
        <div className="nav-item">Friends</div>
        <div className="nav-item">Global</div>

        <div className="nav-item">Reload(icon)</div>
      </div>
      <div className="toasts">
        {notifications.map((n, index) => (
          <div
            className="toasty"
            key={n.gem_name + n.name + '_' + index}
            onClick={() => {
              onClick(n.gem_name);
            }}
          >
            <div className="pimgs">
              {/* <img src={pimgs} /> */}
              <Picture isGem img={n.gem_image} />
              <Picture img={n.profile_image_url || collections.placeholders.avatar} />
              {/* <img className="fimg" src={n.gem_image} /> */}
            </div>
            <div className="tinfo">
              <p>
                <b>{n.name}</b> bought 1 <b>{n.gem_name}</b>
              </p>
              <p className="bought">
                0.003 ETH<span className="info">, {n.time} ago</span>
              </p>
            </div>
          </div>
        ))}

        {!loaded && <TransitioningSection fixed={false} />}
      </div>
    </div>
  );
};

interface PProps {
  img: string;
  isGem?: boolean;
}

const Picture = ({ img, isGem }: PProps) => {
  console.log('');
  return (
    <div className={`round-picture ${!isGem && 'right-above'}`}>
      <img id="pic-img" src={img} />
    </div>
  );
};
