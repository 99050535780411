import { Page } from '../Page';
import './MintHomePage.scss';
import '../mint-v2/components/CollectionsForm.scss';

import MintHomeLayout from './layouts/MintHomeLayout';
import { Carousel } from 'react-responsive-carousel';
import { ItemFeaturedProps } from './components/ItemFeatured';
import Item from './components/Item';

import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import Sale from '@common/Sale';
import { TransitioningSection } from '@components/transitioning-section';
import { mainSuite } from '@services/ServiceFactory';
import { debug } from '@common/LogWrapper';
import { Optional } from '@storyverseco/svs-types';
import { CollectionData } from '@assets/homepage';
import { ModalDrawer } from '@components/modaldrawer/ModalDrawer';
import ItemInfo from './components/ItemInfo';
import { HostedApp } from '@components/hosted-app/HostedApp';
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';

import { useNavigate, useParams } from 'react-router-dom';
import debounce from '@common/Debounce';
import { PathParam } from '@common/PathParam';
import { IStory } from '@storyverseco/svs-story-suite';
import { GemzItem, getPagePath } from '@common/GemzItem';
import { useGemz } from '@hooks/useGemz';
import { GemzActionType } from '../../context/gemz/GemzActionType';
import { LoadState } from '../../common/LoadState';
import TopBar from './components/TopBar';

const log = debug('app:pages:MintHomePage');

const getCollectionData = (sale: Sale): Optional<CollectionData> => {
  // @TODO: Type of Sale is no longer safe since it's updated dynamically
  const { authorInfo, listing } = sale as any;
  if (authorInfo && listing) {
    return {
      thumb: listing.banner,
      url: listing.url,
      avatar: authorInfo.avatar,
      author: authorInfo.name,
      footnote: authorInfo.footnote,
    };
  }

  return undefined;
};

type SaleItemTypeMap = Record<string, ItemFeaturedProps['type']>;

const scrollDeltaThreshold = 10;

enum State {
  VIEWER_LOADING,
  LOADING_STORY_DATA,
  SEND_STORY_DATA_TO_GAME,
  WAIT_FOR_GAME_LOAD,
}

export function MintHomePage({ showCreate = false }: { showCreate?: boolean }) {
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const { gemzState, updateFeed, gemzDispatch, loadStoryFromGem, preloadStories } = useGemz();

  const appState = useAppState();

  const params = useParams();

  // const [isLoadingStory, setIsLoadingStory] = useState(false);

  const [showUI, setShowUI] = useState(true);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [isChangingSlide, setIsChangingSlide] = useState(false);

  const carouselRef = useRef<Carousel>();

  const [viewerReady, setViewerReady] = useState(false);

  const feed = gemzState.feed;
  const [disableScroll, setDisableScroll] = useState(appState.isCreate);

  const [snapshotUrl, setSnapshotUrl] = useState<string>();

  useEffect(() => {
    if (appState.showOnboard) {
      navigate('/');
    }
  }, []);

  // Load feed
  useEffect(() => {
    const fetchFeed = async () => {
      await updateFeed();
      if (params?.[PathParam.SalePath]) {
        gemzDispatch({
          type: GemzActionType.BringGemToTop,
          gemPath: `/${params[PathParam.SalePath]}`,
        });
      }
      setCurrentSlide(0);
    };

    fetchFeed();
  }, [params, updateFeed]);

  const currentFeedItem: GemzItem | undefined = feed?.[currentSlide];

  // Add viewer callback
  useEffect(() => {
    const viewerCallback = async () => {
      console.warn('Viewer is ready');
      if (location.href.includes('create')) {
        mainSuite.navbarService.sendClientEvent('GOTO_GAME_CREATOR');
      }
      setViewerReady(true);
      // mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);
      // Send event for the game to load the story (fetch data before hand)
    };

    const storyReadyCallback = (gemData: { imageUrl: string; name: string; id: string }) => {
      // if the carousel is scrolling the iframe should just deny the request to show the game, so iframe stays hidden
      // if the carousel is on another story, denies too
      // if the carousel in on the game spot, accepts and shows the carousel

      // current slide info
      // console.warn('>>> currentFeedItem.gemname', currentFeedItem.gemname, '-> gemName', opts.gemName);
      if (gemData.id !== currentFeedItem.id) {
        console.error('>>> game gem does not match website item. Avoiding to display game.');
        return;
      }

      if (carouselRef.current.state.swipeMovementStarted || carouselRef.current.state.swiping) {
        console.error('>>> carousel is swiping. Avoiding to display game.');
        return;
      }

      console.warn('>>> website - storyReady callBack', gemData);

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: true,
      });

      setDisableScroll(appState.isCreate);
    };

    const storyEndCallback = () => {
      console.warn('storyEnd');

      // --------------------------------
      // AI generation game ended
      if (appState.isCreate) {
        updateFeed().then(() => {
          setDisableScroll(false);

          appDispatch({
            type: AppActionType.ToggleEnableNavbar,
            navbarEnabled: true,
          });

          appDispatch({
            type: AppActionType.UpdateIsCreate,
            isCreate: false,
          });

          // todo: ideally, we should move to next slide,
          // todo: but if we do not repload the page for some reason stories wont load anymore
          navigate('/');

          //  auto-navigate to next slide after we bough the story gem
          // if (currentSlide < feed.length - 1) {
          //   goToNextSlide();
          // } else {
          //   goToPrevSlide();
          // }

          return;
        });
      }

      // --------------------------------
      // Normal game ended

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: false,
      });
      //  auto-navigate to next slide after we bough the story gem
      if (currentSlide < feed.length - 1) {
        goToNextSlide();
      } else {
        goToPrevSlide();
      }
    };

    const storyScrollCallback = async (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
      // console.warn('>>> storyScrollCallback', disableScroll);
      if (disableScroll) {
        return;
      }
      doScrollCustom(opts);
      setShowUI(true); // always show ui while scrolling
    };

    const storySnapshotCallback = async (snapshot: string) => {
      if (!snapshot) {
        return;
      }
      const wallet = await mainSuite.navbarService.api.getWallet();
      if (!wallet) {
        console.error(`Cannot buy gem without 'wallet'.`);
        return;
      }

      // if we just created the story, then we upload the snapshot to S3 then update db
      if (appState.isCreate) {
        const imgUrl = await uploadFileWithSignedPost({
          b64: snapshot,
          key: `${wallet.address}_${Date.now()}.jpg`,
        });

        setSnapshotUrl(imgUrl);

        console.log({ imgUrl });
      }
    };

    // todo(Cai): we should use gem unique ids for all gem related transactions
    const buyGemCallback = async ({ story, gemData, correctChoices }) => {
      await mainSuite.navbarService.api.signSession();

      const wallet = await mainSuite.navbarService.api.getWallet();
      if (!wallet) {
        console.error(`Cannot buy gem without 'wallet'.`);
        return;
      }
      const twitter = await mainSuite.navbarService.api.twitterService.auth.get();
      if (!twitter) {
        console.error(`Cannot buy gem without 'twitter'.`);
        return;
      }
      try {
        const creatorFeed = gemzState.feed.filter((item) => item.creator_address.toLowerCase() === wallet.address.toLowerCase());

        const mintData = {
          data: {
            creatorAddress: appState.isCreate ? wallet.address : currentFeedItem.creator_address,
            storyId: appState.isCreate ? creatorFeed.length : currentFeedItem.story_index,
            ownerAddress: wallet.address,
            txhash: 'txhash',
            confirmed: false,
            username: twitter.handle,
            gemName: appState.isCreate ? gemData.name : currentFeedItem.gemname,
            storyData: encodeURI(JSON.stringify(story)),
            story_image: appState.isCreate ? snapshotUrl : currentFeedItem.story_image,
            correctChoices,
          },
        };

        const response = await fetch('https://pipeline.beta.pnk.one/gems/minted', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(mintData),
        });

        if ((await response.json()).error) {
          throw new Error('Something went wrong!');
        }

        console.warn('>>> https://pipeline.beta.pnk.one/gems/minted', response, mintData);

        // send purchase outcome back to the game/viewer
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'success');
      } catch (e) {
        console.log(e);
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'error');
      }
    };

    const storyShowUICallback = async (enabled: boolean) => {
      setShowUI(enabled);
    };

    const onAiCreateStoryStart = () => {
      console.warn('>>> onAiCreateStoryStart. disabling scroll');
      appDispatch({
        type: AppActionType.ToggleEnableNavbar,
        navbarEnabled: false,
      });
      setDisableScroll(true);
    };

    const onAiCreateComplete = () => {
      console.warn('>>> onAiCreateComplete.');
      // todo: update bottomNavbarAI UI
    };

    const onShowAiFormCallback = (enabled: boolean) => {
      console.warn('>>> onShowAiFormCallback.');
      appDispatch({
        type: AppActionType.UpdateIsAiForm,
        isAiForm: enabled,
      });
    };

    mainSuite.navbarService.on('CAI_IS_HERE', viewerCallback);
    mainSuite.navbarService.on('STORY_READY', storyReadyCallback);
    mainSuite.navbarService.on('BUY_GEM', buyGemCallback);
    mainSuite.navbarService.on('STORY_END', storyEndCallback);
    mainSuite.navbarService.on('STORY_SCROLL', storyScrollCallback);
    mainSuite.navbarService.on('STORY_SNAPSHOT', storySnapshotCallback);
    mainSuite.navbarService.on('SHOW_UI', storyShowUICallback);
    mainSuite.navbarService.on('GENERATE_AI_STORY', onAiCreateStoryStart);
    mainSuite.navbarService.on('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
    mainSuite.navbarService.on('SHOW_AI_FORM', onShowAiFormCallback);

    return () => {
      mainSuite.navbarService.off('CAI_IS_HERE', viewerCallback);
      mainSuite.navbarService.off('STORY_READY', storyReadyCallback);
      mainSuite.navbarService.off('BUY_GEM', buyGemCallback);
      mainSuite.navbarService.off('STORY_END', storyEndCallback);
      mainSuite.navbarService.off('STORY_SCROLL', storyScrollCallback);
      mainSuite.navbarService.off('STORY_SNAPSHOT', storySnapshotCallback);
      mainSuite.navbarService.off('SHOW_UI', storyShowUICallback);
      mainSuite.navbarService.off('GENERATE_AI_STORY', onAiCreateStoryStart);
      mainSuite.navbarService.off('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
      mainSuite.navbarService.off('SHOW_AI_FORM', onShowAiFormCallback);
    };
  }, [currentFeedItem, gemzState.feed, snapshotUrl, disableScroll, updateFeed]);

  // load story
  useEffect(() => {
    if (!feed?.length) {
      return;
    }
    if (!viewerReady) {
      return;
    }
    if (!currentFeedItem) {
      return;
    }
    if (currentSlide === -1) {
      return;
    }

    // TODO: currently, game automatically hides AI prompt when PLAY_STORY event is sent,
    //       so we're preventing normal load for now
    if (showCreate) {
      preloadStories(currentSlide);
      return;
    }
    // load first slide
    // if (showCreate && currentSlide !== 0) {
    //   return;
    // }

    console.log({ currentFeedItem });

    let stale = false;
    const loadStory = async () => {
      const storyData = await loadStoryFromGem(currentFeedItem);

      if (stale) {
        return;
      }

      playStory({ currentEntry: currentFeedItem, storyData });
    };

    console.warn('>>> website - loading story data...');

    loadStory().then(() => {
      if (stale) {
        return;
      }
      preloadStories(currentSlide);
    });

    return () => {
      stale = true;
    };
  }, [viewerReady, currentFeedItem, currentSlide, feed, showCreate, preloadStories]);

  // play the story once it has been loaded,
  // and only if the carousel is not in the middle of changing the slide
  const playStory = useMemo(
    () =>
      debounce(({ currentEntry, storyData }) => {
        console.log('>>> storyData', { storyData });

        mainSuite.navbarService.sendClientEvent('SET_GEM', {
          image: currentEntry.gemimage,
          name: currentEntry.gemname,
          id: currentEntry.id,
        });

        mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);
      }, 500),
    [],
  );

  // todo(jb)
  // todo: when we use carousel by internal swiping functionality, slides are seamless and infinite
  // todo: but when we use our custom stuff, slides dont behave seamless. we need to figure out a solution for this.

  const goToNextSlide = useMemo(() => debounce(() => carouselRef.current?.increment(), 700, true), [carouselRef]);
  const goToPrevSlide = useMemo(() => debounce(() => carouselRef.current?.decrement(), 700, true), [carouselRef]);

  // todo(jb): tried to use this instead but no luck
  // const goToNextSlide = useMemo(() => debounce(() => carouselRef.current?.onSwipeForward(), 700, true), [carouselRef]);
  // const goToPrevSlide = useMemo(() => debounce(() => carouselRef.current?.onSwipeBackwards(), 700, true), [carouselRef]);

  const doScrollCustom = (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
    // console.warn('>>> doCustomScroll', disableScroll);
    if (disableScroll) {
      return;
    }

    if (!carouselRef.current) {
      return;
    }

    if (Math.abs(opts.deltaY) < scrollDeltaThreshold) {
      return;
    }
    let deltaY = opts.deltaY;
    if (opts.webkitDirectionInvertedFromDevice) {
      deltaY = -deltaY;
    }
    const { selectedItem, itemSize } = carouselRef.current.state;
    if (opts.deltaY < -scrollDeltaThreshold && selectedItem < itemSize - 1) {
      // we want the slides to be infinite and seamless
      goToNextSlide();
    }
    if (opts.deltaY > scrollDeltaThreshold && selectedItem > 0) {
      // we want the slides to be infinite and seamless
      goToPrevSlide();
    }
  };

  // handle scroll events for carousel
  const doScroll = useCallback(
    (e: WheelEvent) => {
      // console.warn('>>> doScroll', disableScroll);
      if (disableScroll) {
        return;
      }
      doScrollCustom({
        deltaY: e.deltaY,
        webkitDirectionInvertedFromDevice: (e as any).webkitDirectionInvertedFromDevice,
      });
    },
    [carouselRef, goToNextSlide, goToPrevSlide, disableScroll],
  );

  // listen to scroll events
  useEffect(() => {
    if (!viewerReady) {
      return;
    }

    window.addEventListener('wheel', doScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('wheel', doScroll, {
        capture: false,
      });
    };
  }, [doScroll, viewerReady]);

  // replace current URL
  useEffect(() => {
    if (!appState.initialSearchParams) {
      return;
    }
    if (!currentFeedItem) {
      return;
    }
    if (showCreate) {
      return;
    }

    let url = getPagePath(currentFeedItem.gemname);

    if (appState.initialSearchParams.size) {
      url += '?' + appState.initialSearchParams.toString();
    }

    window.history.replaceState({}, null, url);
  }, [currentFeedItem, appState.initialSearchParams, showCreate]);

  // show creator page
  useEffect(() => {
    if (!showCreate) {
      return;
    }

    log('show creator page');
    mainSuite.navbarService.sendClientEvent('GOTO_GAME_CREATOR');
    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: true,
    });
  }, [showCreate]);

  // game URL
  const gameUrl = useMemo(() => {
    if (!appState.initialSearchParams) {
      return null;
    }
    const hostname = window.location.hostname;
    if (hostname.includes('gem-dev') || appState.initialSearchParams.has('gemDevUrl')) {
      return 'https://d70g6vzrdmee5.cloudfront.net/ver-carles-viewer/index.html?iframed=true';
    }
    if (hostname.includes('localhost') && !appState.initialSearchParams.has('gemUrl')) {
      return 'http://localhost:3002?iframed=true';
    }
    return 'https://d70g6vzrdmee5.cloudfront.net/ver-gemz-viewer/index.html?iframed=true';
  }, [appState.initialSearchParams]);

  const loaded = Boolean(gameUrl && gemzState.feedState === LoadState.Loaded && currentFeedItem);

  console.log({
    feed,
    currentFeedItem,
    loaded,
  });

  return (
    <Page className="home-base" title="Collectible Stories" introBg fixedNavbar={true}>
      <MintHomeLayout className="home-state-page-layout" title="Storyverse" subtitle={`Collect & play to watch your story unfold`}>
        {loaded && (
          <Carousel
            ref={carouselRef}
            autoFocus={true}
            axis={'vertical'}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={false} // disabled to avoid scroller jumping craziness
            dynamicHeight={false}
            // autoplay
            autoPlay={false}
            interval={4000}
            stopOnHover={false}
            // swiping
            swipeable={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={5} // default is 5
            verticalSwipe={'standard'} // standard or natural -> natural is inverse direction
            emulateTouch={true}
            transitionTime={500}
            animationHandler={'slide'} // 'slide' | 'fade' | AnimationHandler;
            useKeyboardArrows={true}
            // onSwipeStart={() => {}}
            // onSwipeEnd={() => {}}
            // onSwipeMove={(e: React.TouchEvent) => true}
            // selecting
            // selectedItem={currentSlideRef}
            onChange={(index) => {
              // hide game whenever we start changing the carousel slide
              appDispatch({
                type: AppActionType.GameIsPlaying,
                showGame: false,
              });

              // set flag while carousel is changing slide
              setIsChangingSlide(true);
              window.setTimeout(() => setIsChangingSlide(false), 500);

              // establish slide to change to
              setCurrentSlide(index);
            }}
          >
            {(feed || []).map((sale, index) => (
              <Item key={sale.id} gemimage={sale.story_image || sale.gemimage} onClick={() => {}} />
            ))}
          </Carousel>
        )}

        {loaded && <ItemInfo enabled={true} bottomInfoEnabled={showUI} key={currentFeedItem.id} type={'live'} onClick={() => {}} gemz={currentFeedItem} />}
      </MintHomeLayout>

      {currentFeedItem && (
        <div className={`story-game ${appState.showGame ? 'enabled' : 'disabled'}`}>
          <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
        </div>
      )}

      <TopBar />

      {!loaded && <TransitioningSection fixed={true} />}

      {/* todo: we prolly should display a non blocking spinner while current story is actually loading */}
      {/* {viewerReady && !appState.showGame && currentSale && <TransitioningSection />} */}
    </Page>
  );
}

const getFormDataParams = (fields: Record<string, string>, file: File) => {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  return {
    method: 'POST',
    body: formData,
  };
};

const uploadFileWithSignedPost = async ({ b64, key }: { b64: string; key: string }) => {
  const res: Response = await fetch(b64);
  const blob: Blob = await res.blob();
  const file = new File([blob], 'image.jpg', { type: 'image/jpg' });

  let signedPostData: any;
  console.log('uploadFileWithSignedPost', { key });
  try {
    const response = await fetch(`https://pipeline.beta.pnk.one/gems/minted/img/${key}`);
    signedPostData = await response.json();
    const uploadResponse = await fetch(signedPostData.url, getFormDataParams(signedPostData.fields, file));
    if (!uploadResponse.ok) {
      throw new Error(uploadResponse.statusText);
    }

    const uploadedImageUrl = `https://media.pnk.one/gemz/${key}`;

    return uploadedImageUrl;
  } catch (e) {
    console.log(`Error (uploadFileWithSignedPost):`, e);
    throw new Error(`Error: Could not sign post for '${key}'.`);
  }
};
