import { debug } from '@common/LogWrapper';
import { GemzActionType, useGemzDispatch, useGemzState } from '@context/gemz/GemzContext';
import { useCallback } from 'react';
import { GemzItem } from '@common/GemzItem';
import { LoadState } from '@common/LoadState';
import { IStory } from '@storyverseco/svs-story-suite';

const log = debug('app:hooks:useGemz');

const gemzStoryCache: Record<string, IStory> = {};

async function loadStoryFromGem(gem: GemzItem): Promise<IStory> {
  if (gemzStoryCache[gem.id]) {
    return gemzStoryCache[gem.id];
  }

  const storyDataUrl = gem.content_url.includes('.json')
    ? gem.content_url
    : gem.content_url.replace('character-pass', 'pipeline.beta').replace('view', 'story') + '/13';
  try {
    const storyResponse = await fetch(storyDataUrl);
    const storyData = (await storyResponse.json()) as IStory;
    gemzStoryCache[gem.id] = storyData;
    return storyData;
  } catch (e) {
    log('could not load story from gem:', gem, e);
  }

  return null;
}

export function useGemz() {
  const gemzState = useGemzState();
  const gemzDispatch = useGemzDispatch();

  const updateFeed = useCallback(async () => {
    try {
      gemzDispatch({
        type: GemzActionType.UpdateFeedState,
        feedState: LoadState.Loading,
      });
      const feedResponse = await fetch('https://pipeline.beta.pnk.one/gems/feed/content');
      const feedData = (await feedResponse.json()) as GemzItem[];

      gemzDispatch({
        type: GemzActionType.UpdateFeed,
        feed: feedData,
      });

      return feedData;
    } catch (e) {
      log('useGemz:updateFeed error:', e);
      gemzDispatch({
        type: GemzActionType.UpdateFeedState,
        feedState: LoadState.Errored,
        errorMessage: e.message,
      });
    }
  }, [gemzDispatch]);

  const preloadStories = useCallback(
    async (fromIndex = 0, length = 5) => {
      const loadPromises: Promise<IStory>[] = [];
      for (let i = fromIndex; i < fromIndex + length; i += 1) {
        loadPromises.push(loadStoryFromGem(gemzState.feed[i]));
      }
      const results = await Promise.allSettled(loadPromises);
      for (const result of results) {
        if (result.status === 'rejected') {
          log('error preloading story from gem:', result.reason);
        }
      }
    },
    [gemzState],
  );

  return {
    gemzState,
    gemzDispatch,
    updateFeed,
    loadStoryFromGem,
    preloadStories,
  };
}
